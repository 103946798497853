.loginImg{
    padding: 20px;
    height: 200px;
}

body{
    background: #ececec;
}

.cardLogin{
    border-radius: 10px;
    box-shadow: 0px 8px 20px #00000033;
    width: 350px;
    margin-top: 5%;
}