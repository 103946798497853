/* global */
.logo {
  height: 100%;
  margin-left: 20%;
}

.myHeader {
  background: #f8f9fa !important;
  border: 1px solid #dee2e6;
}

label {
  font-weight: bold;
}

.ant-menu {
  margin-top: 10px !important;
}

/* button hamburguesa */
.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

/* titulos de lar tarjetas */
.ant-card-head-title {
  font-weight: bold;
  font-size: 18px;
  color: #1890ff;
}

/* myDrawer */
.ant-drawer-title {
  height: 80px !important;
  background-color: white;
}

.ant-drawer-wrapper-body {
  background-color: #001529 !important;
}

.ant-drawer-header {
  padding: 0 !important;
}

.ant-drawer-body {
  padding: 0 !important;
}

/* modales */
.ant-modal-title {
  font-weight: bold !important;
}