@media only screen and (min-width : 901px) {
    .logo-home {
        width: 30%;
    }
}

/* Cuando la pantalla es menor a 900px  (tablets y teléfonos inteligentes)*/
@media only screen and (max-width : 900px) {
    .logo-home {
        width: 100%;
    }
}
